@import url('https://js.arcgis.com/4.25/@arcgis/core/assets/esri/themes/dark/main.css');
// @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');

// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles

@import '~@coreui/coreui-pro/scss/coreui.scss';

@import 'fixes'; // Some temp fixes

@import '~@sweetalert2/theme-dark/dark.scss';
// @import "~@sweetalert2/theme-borderless/borderless.scss";

// If you want to add something do it here
@import 'custom';
