// Variable overrides

.logs tbody, td, tfoot, th, thead, tr {
    border-style: none; 
}

.form-multi-select {
    --cui-form-multi-select-select-all-color: white;
    --cui-form-multi-select-options-color: white;
    --cui-form-multi-select-options-hover-color: black;
    --cui-form-multi-select-bg: #ffffff14;
    --cui-form-multi-select-search-bg: none;
    --cui-form-multi-select-border-color: #b1b7c121;
}

.form-multi-select:hover {
    --cui-form-multi-select-select-all-hover-color: white;
    --cui-form-multi-select-options-hover-color: black;
}

.date-picker {
    // background-color: white;
    // color: black;
    width: 250px;
    z-index: 1;

    .form-control {
        color: #5c6873;
        background-color: white;
    }

    .input-group-text {
        width: 17%;
    }
}

.calendar-nav-date .btn {
    font-weight: 600;
    color: rgb(200 207 218 / 95%);
}

.calendar-header-cell-inner {
    color: white;
}

.form-select {
    color: white;
    background-color: #ffffff14;
}