// Here you can add other styles

.card-header-action {
	transition: all ease-in-out 0.2s;
}

.c-dark-theme a:hover {
	color: white !important;
}

.input-group-text {
	width: 300px;

	select {
		display: none;
	}
}

// ESRI Styles

.mapDiv,
#root {
	padding: 0;
	margin: 0;
	height: 100%;
	width: 100%;
}

.esri-icon-non-visible::before {
	content: '\e610' !important;
}

.esri-icon-visible::before {
	content: '\e611' !important;
}

// .esri-layer-list__child-toggle ~ div {
// 	.esri-icon-non-visible::before {
// 		content: '\1e69f' !important;
// 	}
// 	.esri-icon-visible::before {
// 		content: '\1f441' !important;
// 	}
// }

.esri-view {
	height: 100%;
}

// ESRI Styles

a.disabled {
	pointer-events: none;
	cursor: pointer;
}

.swal2-icon {
	margin: 1.5em auto 0.6em !important;
}

.swal2-title {
	font-size: 1.5em !important;
}

table.forestTable {
	tr {
		&:last-child {
			font-weight: bold;
			font-size: 1rem;
		}

		td {
			&:nth-child(2) {
				&::before {
					content: '\20b9';
					margin-inline-end: 0.5rem;
				}
			}
		}
	}
}

.row_modal {
	.modal-dialog {
		max-width: 90vw;

		.modal-content {
			height: 90vh;

			.tab-content {
				padding: 30px;

				&.overflowY {
					.tab-pane {
						height: 70vh;
						overflow-y: scroll;
					}
				}
			}
		}
	}
}

.mh-50 {
	min-height: 50vh !important;
}

table.mh-50 {
	tbody {
		tr {
			max-height: 50px !important;
		}
	}
}

.custom-checkbox {
	div {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.d-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

table {
	th,
	td {
		vertical-align: middle !important;
	}
}

// .table-responsive:has(table.project-layers-table) {
//   max-height: 60vh;
// }

// .table-responsive {
//   max-height: 60vh;
// }

.layers-btns {
	flex-wrap: wrap;
	justify-content: end;

	button {
		margin-top: 1rem;
	}
}

.autocomplete {
	.autocomplete-icon {
		position: absolute;
		right: 15px;
		padding: 5px 6px;
	}

	ul {
		position: absolute;
		top: 100%;
		list-style: none;
		margin: 6px;
		padding: 0;

		li {
			width: 100%;
			max-width: inherit;
			padding: 5px;
		}
	}
}

.webmap-sidebar {
	width: 100%;
	margin: 4rem auto auto 285px;

	.modal-dialog {
		max-width: 77.5vw;
		margin: 0;
	}
}

.layerListContainer {
	padding: 0;
	background-color: #2a2b36;

	#layerList {
		background-color: #282933;

		// LayerList sidebar legend
		.esri-legend {
			background-color: #24252f;
			color: white;

			.esri-layer-list-panel {
				margin: 0;
			}

			.esri-legend__layer {
				margin: 0;
				padding: 10px;
			}
		}

		.esri-layer-list__item-actions-menu-item {
			&:hover {
				background-color: #303030 !important;
			}
		}

		.esri-layer-list__item-actions-menu-item--active {
			background-color: #303030 !important;

			&:hover {
				background-color: #303030 !important;
			}
		}

		.esri-layer-list__item-actions {
			ul {
				background-color: #24252f;

				li {
					&:hover {
						background-color: #303030;
					}
				}
			}
		}
	}

	li {
		background-color: #282933;

		span {
			color: #ffffff;
		}
	}
}

//Hide Legend icon in layer list item
.esri-layer-list__item-actions-menu-item[title='Legend'] {
	display: none;
}

.esri-layer-list__item-actions {
	ul {
		flex-direction: row;
		padding-left: 5px;
		flex-wrap: wrap;

		li {
			display: flex;
			width: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

select {
	&.esri-select {
		option {
			background-color: inherit;
		}
	}
}

.c-main {
	padding: 1rem 0;
	position: relative;
}

.st2 {
	fill-rule: evenodd;
	clip-rule: evenodd;
	fill: var(--ci-primary-color, currentColor);
	stroke: var(--ci-primary-color, currentColor);
	stroke-miterlimit: 10;
}

.project-collapse {
	color: gray;
}

.c-icon-custom {
	height: 1rem !important;
	width: 1rem !important;

	&.md {
		height: 1.2rem !important;
		width: 1.2rem !important;
	}

	&.lg {
		height: 1.3rem !important;
		width: 1.3rem !important;
	}

	&.xl {
		height: 1.5rem !important;
		width: 1.5rem !important;
	}
}

.c-main {
	&:has(.container .fade .webmap-card) {
		height: 100%;
		padding: 0 !important;
	}
}

.webmap-card {
	height: 100%;

	// div {
	//   height: 100%;
	// }
}

#toolbarDiv {
	cursor: default;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: nowrap;
	background-color: transparent;

	div {
		box-shadow: 0 1px 2px rgba($color: #000000, $alpha: 0.3);
	}
}

.esri-custom-expand-widget {
	display: none;
}

.esri-widget {
	.esri-custom-expand-widget {
		display: flex;
	}
}

ul.esri-layer-list__item-actions-list {
	li {
		span.esri-layer-list__item-action-title {
			display: none;
		}
	}
}

.route_gen_navs {
	li {
		display: flex;
		margin-right: 0.5rem;
	}
}

/* Chrome, Safari, Edge, Opera (Remove Number Arrows) */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
	appearance: textfield;
}

.land_comp_table {
	width: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;

	// border-collapse: separate;
	// border-spacing: 0.5rem 1em;
	thead {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	th {
		display: flex;
		align-items: center;
		justify-content: start;
		flex: 1;
		min-width: 9vw;
		max-width: 9vw;
	}

	tr {
		display: flex;
		flex: 1;

		td {
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: start;
			padding: 0.5rem;
			min-width: 9vw;
			max-width: 9vw;
			// &:nth-last-child(2),
			// &:last-child {
			//   width: 100px;
			// }
		}
	}
}

.land_form_group {
	.form-group {
		margin-bottom: 0;

		label {
			margin-bottom: 0;
		}
	}
}

.start-end-aoi,
.bline {
	display: grid;
	place-items: center;

	div {
		&.gantry_container {
			border: 1px solid white;
			border-radius: 10px;
			margin-bottom: 0;
			text-align: center;
			vertical-align: middle;
			padding: 0.5rem;
			margin-bottom: 1rem;
			width: 100%;
		}

		&.form-group {
			margin-bottom: 0;
		}

		&.col-3:not(:last-child),
		&.col-4:not(:last-child) {
			border-right: 1px solid white;
		}

		label {
			margin-bottom: 0;
		}
	}
}

//Fullscreen map
.webmap-cbody {
	padding: 0;

	.c-main {
		padding: 0;

		.container-fluid {
			height: 100%;
			padding: 0;

			.fade.show {
				height: 100%;
			}
		}
	}
}

.land_compensation_container {
	display: flex;
	align-items: start;
	justify-content: center;
	height: 100%;

	.land_container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		// overflow-y: scroll;
		margin-bottom: 1rem;

		.border-right {
			border-right: 1px solid rgba($color: #ffffff, $alpha: 0.3);
		}

		&:first-child {
			// border-right: 1px solid rgba($color: #ffffff, $alpha: 0.3);
			// border-radius: 10%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding: 1rem 0;

			.land_version_container {
				padding: 5px 0;

				&:hover {
					color: var(--light, currentColor);
				}

				span {
					cursor: pointer;
				}
			}
		}
	}
}

.query-modal {
	.modal-dialog {
		max-width: 60%;
	}
}

.modal {
	&.light-theme {
		.modal-body {
			background-color: #3d3e47;
			box-shadow: 0 24px 38px 3px rgba(var(--elevation-base-color), 0.14),
				0 9px 46px 8px rgba(var(--elevation-base-color), 0.12),
				0 11px 15px -7px rgba(var(--elevation-base-color), 0.2);
			color: rgba(255, 255, 255, 0.87);

			a {
				color: #7267d3;

				&:hover {
					color: white;
				}
			}

			button {
				background-color: #4638c2;

				&:hover {
					background-color: #3b2fa4;
				}
			}
		}
	}
}

.container-fluid {
	height: 100%;

	> .fade.show {
		height: 100%;

		> .card {
			height: 100%;
		}
	}
}

.aside-tabs {
	.simplification-tab {
		.form-group:not(.custom-formgroup) {
			display: flex;
			align-items: flex-end;

			div {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-end;
			}
		}

		ul.nav {
			li {
				a {
					// padding: 10px 15px !important;
					height: 40px !important;
				}
			}
		}
	}

	div[data-tab='logs'] {
		&.active {
			// display: flex;
			// flex-direction: column-reverse;
		}
	}
}

.a-sidebar {
	ul {
		.nav-link {
			padding: 0.5rem 0.7rem;
		}
	}
}

.input-radio-container {
	position: relative;
	margin: 1rem 0.5rem;
	margin-right: 0;

	div {
		justify-content: center !important;

		label {
			margin-bottom: 0;
		}
	}
}

.esri-layer-list__item--invisible-at-scale .esri-layer-list__item-title {
	color: coral !important;
}

.overflow-y-overlay {
	overflow-y: overlay !important;
}

.land_result_header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	> div {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	div:not(.land_row) {
		display: flex;
		margin-bottom: 0.5rem;
		align-items: flex-start;

		h6 {
			margin-right: 0.5rem;
			margin-bottom: 0;
		}
	}
}

.crop-rate-modal {
	.modal-dialog {
		max-width: 60vw !important;

		.modal-content {
			max-height: 80vh;
			overflow-y: auto;
		}
	}
}

.ta-header {
	.dropdown-menu {
		.dropdown-item {
			padding: 0;
			min-width: fit-content;
		}
	}
}

.custom-file-upload {
	border: 1px solid rgba($color: #ffffff, $alpha: 0.2);
	border-radius: 10px;

	.input-file-name {
		margin-left: 0.5rem;
	}

	.custom-file-upload-input {
		display: none;
	}
}

.datepicker {
	.input-group {
		flex-wrap: nowrap;

		span {
			width: 30px;
			padding: 3px;
		}
	}

	.dropdown-menu {
		padding: 0;

		.datepicker-navigation {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.5rem 0;
		}

		.datepicker-calendar {
			table {
				tbody {
					td {
						cursor: pointer;

						.datepicker-cell-inner {
							color: rgba($color: #ffffff, $alpha: 0.8);

							&:hover {
								color: white;
							}
						}
					}
				}
			}
		}
	}

	svg {
		path,
		rect,
		polygon {
			fill: white;
		}
	}
}

// .plscadd-table {
//   .collapse {
//     background-color: rgba($color: #ffffff, $alpha: 0.1);

//     // .card-body {
//     //   padding: 0 1rem 0.5rem 1rem;
//     // }
//   }
// }

.bg-hover {
	background-color: rgba($color: #ffffff, $alpha: 0.1);
}

.dgee-modal {
	.modal-dialog {
		max-width: 60vw;
	}

	&.w-70 {
		.modal-dialog {
			max-width: 70vw;
		}
	}

	&.w-80 {
		.modal-dialog {
			max-width: 80vw;
		}
	}

	&.w-90 {
		.modal-dialog {
			max-width: 90vw;
		}
	}

	&.w-100 {
		.modal-dialog {
			max-width: 100vw;
		}
	}

	&.tower-library {
		label {
			display: flex;
			align-items: center;
		}
	}

	&.h-90 {
		max-height: 90vh;
	}
}

.col-12.col-md-6 {
	margin-bottom: 0.5rem;
}

.table-last-bold {
	tr {
		&:last-child {
			font-weight: bold;
			font-size: 1rem;
		}
	}

	&.second-last {
		tr {
			&:nth-last-child(2) {
				font-weight: bold;
				font-size: 1rem;
			}
		}
	}
}

.table-second-last-bold {
	tr {
		&:nth-last-child(2) {
			font-weight: bold;
			font-size: 1rem;
		}
	}
}

.rupee-last {
	td {
		&:last-child {
			&::before {
				content: '\20b9';
				margin-inline-end: 0.5rem;
			}
		}
	}
}

.crop-result {
	table {
		margin-bottom: 2rem;
	}
}

.small-headers {
	th {
		font-size: 0.8rem;
	}
}

.crop-compensation {
	pre {
		margin-bottom: 0;
		white-space: initial;
	}

	.table-responsive {
		margin-top: 2rem;
	}
}

.th-sticky {
	table-layout: fixed;

	th {
		position: sticky;
		top: 0;
		z-index: 1;
		background-color: #181924;
		border-color: #181924;
		border: none;
	}
}

.custom-loader {
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
	pointer-events: none;
}

.tree-compensation {
	.table-responsive {
		overflow-y: visible;
	}

	table {
		overflow-y: visible;
	}
}

// label {
// 	white-space: nowrap;
// 	width: 100%;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// 	display: inline-block;
// }

.overflow-x-hidden {
	overflow-x: hidden;
}

#create_features_controls_content {
	.esri-editor__panel-toolbar {
		display: none;
	}

	.esri-editor__panel-content {
		div {
			&.esri-editor__panel-content__section:nth-child(1) {
				margin-top: 0;
				padding: 10px !important;

				> div:not(:has(.esri-feature-form)) {
					&:not(:has(.esri-editor__feature-templates-container)) {
						display: none;
					}
				}
			}
		}
	}
}

#update_features_controls_content {
	.esri-editor__panel-toolbar {
		display: none;
	}

	.esri-editor__panel-content {
		div {
			&.esri-editor__panel-content__section:nth-child(2) {
				margin-top: 0;

				div {
					display: none;
				}
			}
		}
	}
}

.legend {
	border: 1px white solid;
	border-radius: 0.5rem;
	padding: 2rem 1rem 0 1rem;
	margin: 2rem 0;
	position: relative;

	&::before {
		content: attr(data-legend);
		font-size: 1.2rem;
		background-color: rgb(36, 37, 47);
		position: absolute;
		top: -1rem;
		left: 2rem;
		padding: 0 0.5rem;
	}
}

.ts-upload {
	display: flex;
	align-items: center;
	justify-content: space-between;

	> div {
		height: 200px;
		width: 100%;
		border: 1px solid rgba($color: #ffffff, $alpha: 0.6);
		border-radius: 8px;
		margin: 5px;
		padding: 1rem;

		> div {
			flex: 1;
			height: 100%;
			background-color: #282933;
			padding: 0.5rem;
			cursor: pointer;
			transition: all 0.2s;

			&.isDragOver {
				background-color: rgba($color: #282933, $alpha: 0.4) !important;
				padding: 1rem;
			}

			> div {
				flex: 1;
				height: 100%;
				flex-direction: column;
				border: 1px dashed white;

				.title {
					color: orange;
					text-align: center;
					vertical-align: middle;
				}
			}
		}
	}
}

.application-upload {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	> div {
		height: 200px;
		width: 100%;
		border: 1px solid rgba($color: #ffffff, $alpha: 0.6);
		border-radius: 8px;
		margin: 5px;
		padding: 1rem;

		> div {
			flex: 1;
			height: 100%;
			background-color: #282933;
			padding: 0.5rem;
			cursor: pointer;
			transition: all 0.2s;

			&.isDragOver {
				background-color: rgba($color: #282933, $alpha: 0.4) !important;
				padding: 1rem;
			}

			> div {
				flex: 1;
				height: 100%;
				flex-direction: column;
				border: 1px dashed white;

				.title {
					color: orange;
					text-align: center;
					vertical-align: middle;
				}
			}
		}
	}
}

//Sidebar colors
.project-info {
	color: orange;
}

.route-experience-info,
.tower-schedule-info,
.boq-experience-info,
.proposals-info,
.survey-kml {
	border-width: 1px;
	border-style: solid;
	border-radius: 8px;
	overflow: hidden;
}

.route-experience-info {
	border: 1px solid #3ac3d6;
}

.survey-kml {
	border-color: #17bac7;
}

.tower-schedule-info {
	border-color: #0cafbd;
}

.boq-experience-info {
	border-color: #00a9ae;
}

.proposals-info {
	border-color: #329f9c;
}

.experience-name {
	padding: 0.5rem 1.25rem;
}

.h7 {
	font-size: 0.8rem;
}

.route-gen-navs {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 0 !important;
}

.card {
	border-radius: 8px;
	// overflow: hidden;

	// &:hover {
	// 	border: 1px solid aqua !important;
	// }
}

// div.e-tab-header[role='toolbar'] {
// 	display: none;
// }

.ts-schedule-table {
	border-collapse: separate;

	&:not(.released) {
		tr {
			th,
			td {
				&:nth-child(3) {
					position: sticky !important;
					left: 200px;
					z-index: 10;
					background-color: #181924;
				}
			}
		}
	}

	thead {
		tr {
			th {
				width: 'fit-content';
				min-width: 100px;

				&:nth-child(1),
				&:nth-child(2) {
					position: sticky !important;
					left: 0;
					z-index: 10;
					background-color: #181924;
				}

				&:nth-child(2) {
					left: 100px;
				}
			}
		}
	}

	tbody {
		tr {
			td {
				width: 'fit-content';
				min-width: 100px;

				border: 1px solid rgba(255, 255, 255, 0.1);

				&:nth-child(1),
				&:nth-child(2) {
					position: sticky !important;
					left: 0;
					z-index: 10;
					background-color: #181924;
				}

				&:nth-child(2) {
					left: 100px;
				}
			}
		}
	}

	&:not(.released) {
		td {
			padding: 0 !important;
		}
	}

	td {
		white-space: nowrap;

		text-align: center;

		.css*-singleValue {
			display: none;
		}

		input {
			border-radius: 0;
			border-top: none;
		}

		> span {
			margin: 0 10px;
		}
	}
}

.w-200px {
	width: 200px;
}

.w-400px {
	width: 400px;
}

.w-fit {
	width: fit-content !important;
}

.uploadTsModal {
	@media (min-width: 576px) {
		.modal-dialog {
			max-width: 75%;
		}
	}

	@media (min-width: 1300px) {
		.modal-dialog {
			max-width: 50%;
		}
	}
}

.project {
	&.activated {
		border: 1px solid dodgerblue !important;
	}
}

.pe-none {
	cursor: wait;
	pointer-events: none;
}

nav {
	&[aria-label='pagination'] {
		display: flex !important;
		justify-content: center;
		align-items: center;
		margin-top: 1rem;
	}
}

.collapsable-form {
	label {
		margin-bottom: 0;
	}

	.row {
		display: flex;
		align-items: center;

		.row {
			justify-content: center;
		}
	}

	div {
		&.card {
			height: fit-content;

			header {
				cursor: pointer;
			}
		}

		&.error {
			border: 1px solid var(--danger-dark-theme) !important;
			border-radius: 0.25rem;
		}
	}
}

.excel-table {
	td {
		width: fit-content;
		min-width: 150px;

		border: 1px solid rgba(255, 255, 255, 0.1);
		padding: 0 !important;

		//Center text without dropdowns
		text-align: center;
	}
}

.modal-body {
	form {
		> div {
			display: flex;
			align-items: center;
		}
	}
}

.react-spreadsheet {
	height: 70vh !important;
	overflow: scroll;

	.table-responsive {
		overflow: visible;
	}
}

.switch2d3dContainer {
	input {
		border: none;
		box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;
	}
}

.crossing-line-details {
	.row {
		.col-12 {
			.row {
				.col-12 {
					.row {
						.col-6 {
							&:first-child {
								font-weight: 600;
							}
						}
					}
				}
			}
		}
	}
}

.crossing-list-tabs {
	.crossing-container {
		.crossing-header {
			background-color: darkslategray;
		}

		.job-container {
			border: 1px solid aquamarine;
			border-radius: 4px;

			.status-tracking {
				display: flex;
				flex-direction: column;

				div {
					&:nth-child(2) {
						display: flex;
						flex: 1;
						border: 1px solid gray;
						border-radius: 8px;
					}
				}
			}
		}
	}
}

.custom-dropdown-menu {
	&.show {
		section {
			header {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.document-detail-item {
				display: flex;
				align-items: center;
				justify-content: space-between;

				&:not(:last-child) {
					margin-bottom: 0.5rem;
				}
			}

			.card-body {
				padding: 0.5rem 1.25rem;
			}
		}
	}
}

.esri-expand__icon--expanded {
	calcite-icon {
		display: none;
	}
}

.tbody-scroll {
	div.table-responsive {
		max-height: 25rem;

		thead,
		th {
			position: sticky;
			top: 0;
			z-index: 1;

			background-color: #181924;

			&.bg-normal {
				background-color: #3d3e47;
			}

			border: none;
		}
	}

	&.bg-normal {
		div.table-responsive {
			thead,
			th {
				background-color: #3d3e47;
			}
		}
	}
}

.mail-config {
	width: 100%;

	.modal-dialog {
		max-width: 50vw;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.c-sidebar {
	&.left-panel {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		> div {
			width: 100%;
		}
	}
}

.c-sidebar-main {
	height: calc(100vh - 100px);

	.project-cards {
		margin: 0 5px 0 10px;

		> div {
			margin-bottom: 0.5rem !important;
		}
	}
}

.td-checkbox {
	.checkbox {
		margin: 0 !important;
		display: flex;
		align-items: center;
		justify-content: center;

		input[type='checkbox'] {
			margin: 0 !important;
			min-height: 34.5px;
		}
	}
}

.carousel-container {
	display: flex;
	flex: 1;
	min-height: 100vh;
	min-width: 100vw;

	div {
		display: flex;
		flex: 1;
		min-height: 100vh;
		min-width: 100vw;
	}
}

.w-min-200 {
	min-width: 200px;
}

// Login page custom styles

.l-input::placeholder {
	color: white;
}

.l-input:focus {
	background-color: rgba(255, 255, 255, 0.9) !important;
}

.l-input:target {
	background-color: rgba(255, 255, 255, 0.7);
	color: 'black';
}

.l-btn {
	height: 2.5rem;
	min-width: 7rem;
	border-radius: 1.3rem;
	font-size: 1rem;
	color: black;
	background-color: white;
	opacity: 0.6;
}

.l-btn:hover {
	opacity: 1 !important;
	background-color: #321fdb !important;
	color: white !important;
}

.contact-admin {
	font-size: 0.85rem;
	color: whitesmoke;
	text-align: center;
	font-style: italic;
	margin-top: 2%;
}

// Bulk Upload Excel Template Download

.cloud-download:hover {
	cursor: pointer;
	color: lightgrey;
}

.cloud-download:focus {
	outline: none;
}

.last-row-bold {
	tr {
		&:last-child {
			font-weight: bold;
		}
	}
}
#portal-footer-id {
	min-height: 6rem !important;
	width: 100%;
	color: #fff;
	background-color: #185fcad6 !important;
	display: flex;
	flex-direction: column;
	width: 100%;

	.portal-footer {
		background-color: #2a6ccfd6;
		padding: 2rem;

		.footer-item {
			display: flex;
			justify-content: center !important;
			align-items: center;
		}
	}

	.footer-box {
		display: flex;
		background-color: #185fcad6;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		border: 1px solide black;
		padding: 1rem;
		a {
			color: #fff;
		}
	}
}
.horizontal-slider {
	width: 100% !important;
	min-height: 80% !important;
	position: unset;
	padding-left: 3rem;
	padding-right: 6rem;

	.react-multiple-carousel__arrow {
		position: absolute;
	}

	.react-multiple-carousel__arrow--left {
		left: calc(-0.1% + 1px);
	}

	.react-multiple-carousel__arrow--right {
		right: calc(-0.1% + 1px);
	}
}
#portal {
	height: 100vh;
	width: 100vw;
	// backgroundImage: `url(${slides[0]})`,
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	// justify-content: center;

	#nav-bar {
		height: 3rem;
		width: 100%;
		background-color: #185fcad6;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		color: white;

		li {
			list-style-type: none;
			height: 100%;
			width: 7%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		#adm:hover {
			background-color: #696464;
		}
	}

	.marquee-container {
		width: 100%;
		overflow: hidden;
		background-color: #2a6ccfd6; // light gray
		padding: 9px 0;
		.marquee-text {
			display: flex;
			width: 100%;
			justify-content: space-around;
			font-size: 12px;
			color: #fff; // dark gray
			white-space: nowrap;
			animation: marquee 30s linear infinite;

			div {
				margin-right: 20px;
				display: flex;
				.info-text {
					color: #54b4d3;
				}
				.warning-text {
					color: #e4a11b;
				}
			}
		}
	}
	.marquee-text:hover {
		cursor: pointer;
		animation-play-state: paused;
	}

	@keyframes marquee {
		0% {
			transform: translateX(100%);
		}
		100% {
			transform: translateX(-100%);
		}
	}

	#about {
		position: relative;
		top: 15vh;
		height: 25rem;
		width: 90%;
		padding: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: space-around;
		color: black;
		.link {
			text-decoration: none;
			padding: 0.5rem;

			.app-card {
				height: 21rem;
				width: auto;
				max-width: 15rem;
				margin-left: 1rem !important;
				background-color: #fff;
				border: 1px solid #f0f0f0;

				.img {
					height: 9rem;
					width: 100%;
					margin-bottom: 6px;
					border-radius: 2px;
					border-bottom-left-radius: none;
					border-bottom-right-radius: none;
					background-color: transparent;
					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}

				.body {
					height: 60%;
					background-color: transparent;
					color: black;
					overflow: auto;
					border-top: 1px solid black;
				}

				::-webkit-scrollbar {
					width: 3px;
					height: 6px;
				}

				/* Track */
				::-webkit-scrollbar-track {
					background-color: white;
					border-radius: 1rem;
				}

				/* Handle */
				::-webkit-scrollbar-thumb {
					background-color: rgba(145, 141, 145, 0.8);
					border-radius: 1rem;
				}

				/* Handle on hover */
				::-webkit-scrollbar-thumb:hover {
					background-color: rgba(52, 52, 53, 0.6);
				}
			}

			.app-card:hover {
				transition: ease-in 0.2s;
				box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
				border: 1px solid #000;
				scale: 1.01;
			}
		}

		.link:hover {
			box-shadow: rgba(84, 84, 91, 0.2) 0px 7px 29px 0px;

			scale: 1.01;
		}
	}

	@media only screen and (max-width: 600px) {
		#about {
			position: relative;
			top: 7vh;
			height: 90%;
			width: 90%;
			// border: 1px solid red;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			color: black;

			.card-body {
				height: 3rem;
				h4 {
					font-size: 15px;
					padding: 0;
					margin: 5px;
				}

				.heading-img {
					height: 3rem;
					width: 3rem;
					background-color: rgb(181, 189, 196);
					top: -29px;
					right: -16px;
					border-radius: 65px;
					position: absolute;
					background-repeat: no-repeat;
					background-size: 60%;
					background-position: center;
				}
			}
		}
	}
}

.app:hover {
	transform: scale(1.03);
}

#base-container {
	height: 100vh !important;
}

.customDynamicMap {
	height: 100vh;

	#title {
		background-color: rgba(51, 51, 51, 0.9);
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
		position: relative;
	}

	.logo-container {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		position: absolute;
		left: -2px;
		top: -2px;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 20px;
			height: 20px;
		}
	}

	h1 {
		font-size: 16px;
		font-weight: bold;
		color: white;
	}

	h2 {
		font-size: 12px;
		color: white;
	}
}

#re-analysis {
	padding: 10px 0;
	background-color: white;
	min-width: 200px;
	height: 400px;
	border-radius: 2px;
	display: flex;
	align-items: center;
	flex-direction: column;

	h2 {
		color: black;
	}
}

.tbody-scroll-widget {
	div.table-responsive {
		max-height: 50vh;

		thead,
		th {
			position: sticky;
			top: 0;
			// z-index: 1;
			background-color: white;
			border: none;
		}
	}
}

.application-edit {
	position: absolute;
	top: 40px;
	right: 30px;
	height: 25px !important;
	width: 25px !important;
	border-radius: 50%;
	background-color: white !important;
}

.application-image-preview {
	width: 100px;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

#aside-panel {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 25rem;
	background-color: white;
	transition: all 300ms ease-in-out;
}

#re-layer-list-aside-panel {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 25rem;
	background-color: white;
	transition: all 300ms ease-in-out;

	#re-layer-list-content {
		overflow-y: auto;
		height: calc(100vh - 42px);
	}
}

.layer_table {
	width: 25rem;
}

.td-border-none {
	td {
		border-top: none;
	}
}

.th-center {
	th {
		&:not(:nth-child(1)) {
			div {
				display: flex !important;
				align-self: center;
				text-align: center;
			}
		}
	}
}

.doc_version {
	display: flex;
	align-items: center;

	&::before {
		display: flex;
		content: '';
		height: 10px;
		width: 10px;
		border-radius: 50%;
		margin-right: 10px;
	}
	&[data-version='generated'] {
		&::before {
			background-color: #57cea2;
		}
	}
	&[data-version='uploaded'] {
		&::before {
			background-color: #4377d0;
		}
	}
}

.react-spreadsheet {
	margin-bottom: 5rem;
	nav {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.esri-feature-table {
	height: 50vh !important;
	bottom: 0;
}

#project-stage-card {
	height: auto !important;

	header {
		border-radius: 5px;
	}

	.project.card {
		height: 150px;

		footer {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
}

#collaboration-sketch-dropdown {
	> div {
		width: 200px !important;

		div[class*='menu'] {
			color: #000;
		}
	}
}

.w-100 {
	div[class*='control'] {
		width: 100%;
	}
}

.btn-danger {
	background-color: #dc3545 !important;
}

.danger {
	transition: all 0.2s ease-in-out;
	color: #dc3545 !important;

	// &:hover {
	// 	color: white !important;
	// }
}

.grid-view {
	display: grid;
	grid-template-columns: auto auto auto;
	gap: 20px;

	.grid-item {
		height: 100%;

		a {
			height: 100%;
		}
	}
}

.project-item {
	div {
		span {
			color: #9c94e0;
		}
	}
}

.icon-container {
	width: 100px;
	height: 100px;
}

.icon {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.text-area {
	min-height: 150px;
}
